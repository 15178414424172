<template>
  <div>
    <div class="sctp-container sctp-pad-tb20">
      <div class="" style="margin-top: -10px;">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>服务商中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="sctp-bg-white sctp-pad-tb10 sctp-mar-t10">
        <condition @select="onConditionSelect" :data="condition"></condition>
      </div>
      <div class="sctp-mar-t10">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-row>
              <el-col :span="24">
                <div class="sctp-pad-tb10 sctp-pad-lr15 sctp-bg-white">
                  <div class="sctp-flex sctp-flex-sb">
                    <el-button-group>
                      <el-button
                        size="mini"
                        @click="goodsInfoOrderBy('')"
                      >
                        综合
                      </el-button>
                      <el-button size="mini" @click="goodsInfoOrderBy('updatetime')">最新发布 {{
                          orderByStatus('updatetime') === 0 ? '' : orderByStatus('updatetime') > 0
                            ? '▲' : '▼'
                        }}
                      </el-button>
                    </el-button-group>
                    <el-input size="mini" v-model="searchForm.shopName" placeholder="服务商名"
                              :style="{width:'auto'}">
                      <el-button @click="onShopNameSearchClick" slot="append"
                                 icon="el-icon-search"></el-button>
                    </el-input>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="sctp-mar-t10">
              <template v-for="item in serviceProvider">
                <a target="_blank" :href="'/service-provider/view/' + item.shopid" :key="item.shopid">
                  <el-col :span="6" class="sctp-mar-b10">
                    <div class="sctp-flex sctp-flex-column sctp-bg-white">
                      <el-image
                        fit="cover"
                        style="height: 200px;width: 100%;"
                        :src="item.logo"
                      ></el-image>
                      <div class="sctp-pad8">
                        <el-row>
                          <el-col :span="18">
                            <div class="sctp-ellipsis">{{ item.shopname }}</div>
                          </el-col>
                          <el-col :span="6" class="sctp-tr">
                            <i class="el-icon-s-shop sctp-color-main sctp-font-16"></i>
                          </el-col>
                        </el-row>
                        <!--<div class="sctp-info sctp-mar-t5">成交额: <span class="sctp-color-main">¥3710</span></div>-->
                        <!--<div class="sctp-info sctp-mar-t5 sctp-flex">-->
                        <!--  <div class="sctp-flex-item-1">成交量：37</div>-->
                        <!--  <div class="sctp-flex-item-1"></div>-->
                        <!--</div>-->
                        <div class="sctp-info sctp-flex sctp-flex-sb sctp-mar-t5">
                          <div>{{
                              address(item.provinceCode, item.cityCode, item.countyCode)
                            }}
                          </div>
                          <div>{{ item.userType }}</div>
                          <!--<div><i class="el-icon-phone sctp-color-main sctp-font-18" style="transform: rotateY(180deg)"></i></div>-->
                        </div>
                      </div>
                    </div>
                  </el-col>
                </a>
              </template>
              <el-col :span="24">
                <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white">
                  <el-pagination
                    :current-page="pageConfig.page"
                    :page-size="pageConfig.limit"
                    @size-change="pageConfig.handleSizeChange"
                    @current-change="pageConfig.handleCurrentChange"
                    :page-sizes="pageConfig.pageSizes"
                    layout="prev, pager, next, total, sizes"
                    :total="pageConfig.total">
                  </el-pagination>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <div class="">
              <div class=" sctp-bg-white">
                <div class="pad-t15 pad-b5">
                  <div class="slider-card-title">最近发布了服务的码家</div>
                </div>
                <div class="sctp-pad10">
                  <el-row :gutter="10">
                    <template v-for="item in newsShops">
                      <a target="_blank" :href="'/service-provider/view/' + item.shopid" :key="item.logo">
                        <el-col :span="8">
                          <el-image
                            style="height: 80px;width: 100%;"
                            fit="cover"
                            :src="item.logo"
                          ></el-image>
                        </el-col>
                      </a>
                    </template>
                  </el-row>
                </div>
              </div>
              <div class="sctp-bg-white sctp-mar-t10">
                <div class="pad-t15 pad-b5">
                  <div class="slider-card-title">最近发布的服务</div>
                </div>
                <div class="sctp-pad10">
                  <template v-for="item in newsServices">
                    <a target="_blank" :href="'/service/view/' + item.serviceid"
                       :key="item.serviceid">
                      <div class="sctp-info sctp-mar-b5 sctp-pad-b5 sctp-lh-normal"
                           style="border-bottom: 1px #ccc dashed;">
                        <div>{{ item.servicename }}</div>
                        <div class="sctp-red sctp-mar-t5">¥{{ item.serviceprice }}</div>
                        <div class="sctp-mar-t5"></div>
                      </div>
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import area from '@/resources/js/area'
import {service,common} from "@/apis";

export default {
  components: {
    condition: () => import('@PAGE/home/QueryMenu.vue')
  },
  data() {
    return {
      serviceProvider: [], // 服务提供商
      services: [],
      searchForm: {
        shopName: '',
        provinceCode: null,
        cityCode: null,
        orderBy: null,
      },
      pageConfig: {
        page: 1,
        limit: 20,
        pageSizes: [1, 2, 5, 10].map(item => item * 20),
        total: 0,
        handleSizeChange: (limit) => {
          this.pageConfig.limit = limit;
          this.getServiceProvider()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.getServiceProvider()
        }
      },
      newsShops: [],
      newsServices: []
    }
  },
  methods: {
    goodsInfoOrderBy(field) {
      let result = null;
      if (field) {
        let ret = this.orderByStatus(field);
        if (ret === 0) {
          result = {field: field, orderByType: 'ASC'};
        } else if (ret === 1) {
          result = {field: field, orderByType: 'DESC'};
        }
      }
      this.searchForm.orderBy = result;
      this.getServiceProvider();
    },
    onShopNameSearchClick() {
      if (!this.searchForm.shopName) {
        return;
      }
      this.getServiceProvider();
    },
    getServiceProvider() {
      let {pageConfig: {limit, page}, searchForm} = this;
      return service.serverProvider({
        page: page, limit: limit,
        shopName: searchForm.shopName,
        provinceCode: searchForm.provinceCode,
        cityCode: searchForm.cityCode,
        orderBy: searchForm.orderBy,
      }).then(res => {
        const {retdata, count = 0} = res;
        this.serviceProvider = retdata;
        this.pageConfig.total = count
      })
    },
    /**
     * 获取最新发布服务和最新发布服务的的商家
     */
    getNewsServiceAndProvider() {
      return this.$request.post({
        reqcode: '1133',
        reqdesc: '最新服务和服务商'
      }).then(res => {
        const {shops, services} = res.retdata;
        this.newsServices = services;
        this.newsShops = shops
      })
    },
    onConditionSelect({label, target}) {
      const {value} = target;
      switch (label) {
        case '省份':
          this.searchForm.provinceCode = value;
          this.getServiceProvider();
          break;
        case '城市':
          this.searchForm.cityCode = value;
          this.getServiceProvider();
          break;
        default:
          break
      }
    }
  },
  computed: {
    orderByStatus() {
      return function (field) {
        let {orderBy} = this.searchForm;
        if (orderBy && orderBy.field && orderBy.field === field) {
          if (!orderBy.orderByType || orderBy.orderByType === 'ASC') {
            return 1;
          }
          return -1;
        }
        return 0;
      }
    },
    address() {
      return (province, city, county) => {
        const address = area.codeToText([province, city, county]);
        return address[0] + ' ' + address[1]
      }
    },
    condition() {
      const result = [];
      let {provinceCode, cityCode} = this.searchForm;
      result.push({
        label: '省份',
        values: [{
          title: '全部',
        }].concat(area.options
        .map(item => {
          item.title = item.label;
          return item
        }))
      });
      result.push({
        label: '城市',
        values: [{
          title: '全部',
        }].concat(area.options
        .filter(item => !provinceCode || provinceCode === item.value)
        .map(item => item.children || [])
        .reduce((pre, curr) => pre.concat(curr)).map(item => {
          item.title = item.label;
          return item
        }))
      });
      return result
    }
  },
  mounted() {
    common.viewHistory({
      userId: (this.user && this.user.userId) || null,
      id: 12,
      title: '服务商大厅',
      type: 12
    });
  },
  beforeMount() {
    this.getServiceProvider();
    this.getNewsServiceAndProvider()
  }
}
</script>

<style scoped>
/*@formatter:off*/
  /*@formatter:on*/
</style>
